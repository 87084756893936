import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import { Box, Text } from '@primer/react';
import ComponentLayout from '~/src/layouts/component-layout';
import { AccessibilityLink } from '~/src/components/accessibility-link';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h2>{`Usage`}</h2>
    <p>{`The popover component is used to deliver context-specific information and functionality. It’s a small dialog to bring attention to specific user interface elements. It can provide additional information, options, or actions related to a specific element or task. Popovers can be helpful for flows that require light onboarding or instruction.`}</p>
    <p>{`Popovers may contain text, links, and buttons.`}</p>
    <p>{`Popover supports various caret positions, which you can specify, though the default is top. Note that the top-left, bottom-left, top-right, and bottom-right values modify the horizontal alignment of the popover.`}</p>
    <img src="https://user-images.githubusercontent.com/586552/227022626-625dad35-47d5-46ac-ba64-3c1285a37410.png" role="presentation" width="960" alt="A popover component example" />
    <h2>{`Best practices`}</h2>
    <p>{`Use sparingly to avoid cognitive overload. Though they can be used for a variety of things, they should be used sparingly to avoid cognitive overload. It's important to consider the context in which the popover appears. Are there other popovers on the page? Does it appear on page load, or require the user to open the popover?`}</p>
    <p>{`Unlike other messaging components, popovers should never include critical information (such as errors) and should always include a dismiss action.`}</p>
    <h2>{`Accessibility and usability expectations`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Popovers should be operable using the keyboard alone, without requiring the use of a mouse or other pointing device.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`When a popover is opened, focus should be moved to the popover so that keyboard users can interact with it. When the popover is closed, focus should be returned to the element that triggered it. For more on this, see the accessibility guidelines `}<a parentName="p" {...{
            "href": "/guides/accessibility/focus-management"
          }}>{`on focus management`}</a>{`.`}</p>
      </li>
    </ul>
    <h3>{`Built-in accessibility features`}</h3>
    <p>{`Beyond generating a visual container, the component has no built-in accessibility features.`}</p>
    <h3>{`Implementation requirements`}</h3>
    <p>{`As `}<inlineCode parentName="p">{`Popover`}</inlineCode>{` currently lacks an explicit `}<inlineCode parentName="p">{`role`}</inlineCode>{`, make sure to add an explicit `}<inlineCode parentName="p">{`role="complementary"`}</inlineCode>{` – this will identify the component as a content that is "tangentially" related to its surroundings (the equivalent of an `}<inlineCode parentName="p">{`<aside>`}</inlineCode>{` element).`}</p>
    <p>{`When using the component, make sure that the component follows the element that it relates to. This will ensure that the content of the `}<inlineCode parentName="p">{`Popover`}</inlineCode>{` will be read/announced by screen readers immediately after its related element. It will also ensure that any focusable elements inside the `}<inlineCode parentName="p">{`Popover`}</inlineCode>{` will logically follow the related element.`}</p>
    <p>{`Make sure that there is a clear way for a user to dismiss the `}<inlineCode parentName="p">{`Popover`}</inlineCode>{` – for instance, if the component is used to provide additional information, include a button (such as a "Got it" control) that dismisses the `}<inlineCode parentName="p">{`Popover`}</inlineCode>{`. When the component is closed, move focus back to the element that the `}<inlineCode parentName="p">{`Popover`}</inlineCode>{` related to. See the accessibility guidelines on `}<a parentName="p" {...{
        "href": "https://primer.style/guides/accessibility/focus-management"
      }}>{`focus management`}</a>{`.`}</p>
    <h3>{`How to test the component`}</h3>
    <h4>{`Integration tests`}</h4>
    <ul>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`Popover`}</inlineCode>{` follows its related element in the underlying rendered document structure`}</li>
      <li parentName="ul">{`If the `}<inlineCode parentName="li">{`Popover`}</inlineCode>{` contains any focusable controls, these controls logically follow their related element in the overall focus order`}</li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`Popover`}</inlineCode>{` has been given an appropriate `}<inlineCode parentName="li">{`role`}</inlineCode>{`, such as `}<inlineCode parentName="li">{`role="complementary"`}</inlineCode></li>
      <li parentName="ul">{`There is a way for users to close the `}<inlineCode parentName="li">{`Popover`}</inlineCode></li>
    </ul>
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
 <AccessibilityLink label="Popover" mdxType="AccessibilityLink" />
    <h2>{`Related links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "../ui-patterns/feature-onboarding"
        }}>{`Feature onboarding`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      